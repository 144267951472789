<div class="homepage-2">
    <app-scrollup></app-scrollup>
    <div class="main">
        <app-header-two></app-header-two>
        <app-hero-two></app-hero-two>
        <app-promo-two></app-promo-two>
        <app-feature-two></app-feature-two>
        <app-content-three></app-content-three>
        <app-content-four></app-content-four>
        <app-video></app-video>
        <app-pricing-two></app-pricing-two>
        <app-review></app-review>
        <app-team-two></app-team-two>
        <app-cta-two></app-cta-two>
        <app-footer-two></app-footer-two>
    </div>
</div>