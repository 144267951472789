import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-faq-three',
  templateUrl: './faq-three.component.html',
  styleUrls: ['./faq-three.component.css']
})
export class FaqThreeComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
