<div>
    <div class="inner-wrapper d-flex flex-column align-items-center justify-content-between p-4">
        <a href="/">
            <img src="assets/img/logo.png" alt="">
        </a>
        <!-- ***** Thanks Area Start ***** -->
        <div class="thanks-area">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-10 mx-auto pt-4">
                        <img src="assets/img/thanks_thumb.png" alt="">
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 text-center mb-5">
                        <h2 class="fw-4">Thank you for your subscription!</h2>
                        <p class="mt-3">We’ve sent the invoice in your inbox. Let’s get started the awesome things.</p>
                    </div>
                </div>
            </div>
        </div>
        <!-- ***** Thanks Area End ***** -->
        <div class="footer-bottom">
            <!-- Copyright Area -->
            <div class="copyright-area border-0 pt-4 pt-md-0">
                <p>Made with <i class="icofont-heart-alt"></i> By <a href="#">Themeland</a></p>
            </div>
        </div>
    </div>
</div>