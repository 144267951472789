import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blog-three-column',
  templateUrl: './blog-three-column.component.html',
  styleUrls: ['./blog-three-column.component.css']
})
export class BlogThreeColumnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
