<section id="servicos" class="section benifits-area style-two ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Nossos principais serviços</h2>
                    <p class="d-none d-sm-block mt-4">Bentz Bank tem a solução completa!</p>
                    <p class="d-block d-sm-none mt-4">Bentz Bank tem a solução completa!</p>
                </div>
            </div>
        </div>
        <div class="row benifits-content">
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/dinheiro.svg" width="60px" height="60px" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Conta Digital</h3>
                        <p>Mais que uma conta, seu serviço de gestão dia-dia.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up" data-aos-delay="200">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/hands-money.svg" width="90px" height="60px" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Empréstimos</h3>
                        <p>Viabilize sua linha de crédito com as melhores opções.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up" data-aos-delay="400">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/home.svg" width="80px" height="80px" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Financiamento Imobiliário</h3>
                        <p>Faça uma simulação personalizada.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up" data-aos-delay="600">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/home-icon.svg" width="80px" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Crédito com garantia</h3>
                        <p>Linha de crédito especial com os menores juros.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up" data-aos-delay="800">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/pc.svg" width="70px" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Maquininha</h3>
                        <p>Simplicidade, alta tecnologia e taxas competitivas.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up" data-aos-delay="1000">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/descontoRecebiveis.svg" width="60px" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Desconto de Recebíveis</h3>
                        <p>Simplicidade, rapidez e taxas competitivas.</p>
                    </div>
                </div>
            </div>


            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up" data-aos-delay="600">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/consorcio.svg" width="80px" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Consórcio</h3>
                        <p>Equipes especializadas para cartas contempladas, composição de grupos e lances em grupos personalizados.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up" data-aos-delay="800">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/car-icon.svg" width="70px" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Financiamento de veículos</h3>
                        <p>Financiamos veículos novos, usados e autofinanciamento. Condição especial para lojistas.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-4 p-0" data-aos="fade-up" data-aos-delay="1000">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/credit-card-icon.svg" width="60px" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Cartão pré-pago cambial</h3>
                        <p>Cartão pré-pago recarregável aceito em mais de 200 países.</p>
                    </div>
                </div>
            </div>

        </div>
    </div>
</section>