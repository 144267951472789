<section class="section about-app-area ptb_100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-12 col-lg-6 order-2 order-lg-1">
                <!-- About Content -->
                <div class="about-content pt-5 pt-lg-0">
                    <h2>User's Love Appia to Make Cool Landing pages</h2>
                    <p class="pt-3 pb-4">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English. Many desktop publishing packages and web page.</p>
                    <p class="d-none d-sm-block">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable.</p>
                    <!-- Store Buttons -->
                    <div class="button-group store-buttons d-none d-sm-flex">
                        <a href="#">
                            <img src="assets/img/google_play.svg" alt="">
                        </a>
                        <a href="#">
                            <img src="assets/img/app_store.svg" alt="">
                        </a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-6 mx-auto order-1 order-lg-2">
                <!-- About Thumb -->
                <div class="about-thumb rounded-lg bg-overlay">
                    <!-- Play Button -->
                    <a class="play-btn" data-fancybox href="https://www.youtube.com/watch?v=hs1HoLs4SD0">
                        <div class="btn-circle play-animation"></div>
                        <div class="btn-circle play-animation animation-short"></div>
                        <!-- Play Icon -->
                        <div class="play-icon">
                            <i class="fas fa-play"></i>
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</section>