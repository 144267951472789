import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-content-six',
  templateUrl: './content-six.component.html',
  styleUrls: ['./content-six.component.css']
})
export class ContentSixComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
