<div>
    <div class="inner-wrapper d-flex flex-column align-items-center justify-content-between p-4">
        <a href="/">
            <img src="assets/img/logo.png" alt="">
        </a>
        <!-- ***** Download Page Area Start ***** -->
        <div class="download-page-area text-center">
            <div class="container">
                <div class="row">
                    <div class="col-12 inner-content">
                        <div class="download-icon mb-4">
                            <i class="icon icofont-download"></i>
                        </div>
                        <h1 class="mb-4 fw-4">Your download should<br>begin automaticly.</h1>
                        <p>If it doesn’t start automatically, please <a class="color-primary" href="#">click here to downlaod manually</a></p>
                    </div>
                </div>
            </div>
        </div>
        <!-- ***** Download Page Area End ***** -->
        <div class="footer-bottom">
            <!-- Copyright Area -->
            <div class="copyright-area border-0 pt-4 pt-md-0">
                <p>Made with <i class="icofont-heart-alt"></i> By <a href="#">Themeland</a></p>
            </div>
        </div>
    </div>
</div>