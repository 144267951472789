<section id="benifits" class="section benifits-area style-two ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Why Small Business owner's loves Appia</h2>
                    <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                </div>
            </div>
        </div>
        <div class="row benifits-content">
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-3 p-0" data-aos="fade-up">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/promo_5.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Free Trail</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-3 p-0" data-aos="fade-up" data-aos-delay="200">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/promo_6.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Live Chat</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-3 p-0" data-aos="fade-up" data-aos-delay="400">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/promo_7.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Secure Data</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                    </div>
                </div>
            </div>
            <!-- Benifits Item -->
            <div class="col-12 col-sm-6 col-lg-3 p-0" data-aos="fade-up" data-aos-delay="400">
                <div class="benifits-item text-center p-5">
                    <!-- Feature Icon -->
                    <div class="feature-icon mb-3">
                        <img src="assets/img/promo_8.png" alt="">
                    </div>
                    <!-- Benifits Text -->
                    <div class="benifits-text">
                        <h3 class="mb-2">Customizable</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>