<section id="home" class="section welcome-area h-100vh d-flex align-items-center">
    <div class="container">
        <div class="row align-items-center">
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-7 col-lg-6">
                <div class="welcome-intro">
                    <h1>Appia</h1>
                    <h3 class="fw-3 mt-2 mt-sm-3">Make Cool Landing Pages</h3>
                    <p class="my-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Architecto beatae, asperiores tempore repudiandae saepe aspernatur unde voluptate sapiente quia ex.</p>
                    <div class="button-group">
                        <a href="#" class="btn btn-bordered">Download</a>
                        <a href="#" class="btn btn-bordered d-none d-sm-inline-block">Read More</a>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5 col-lg-6">
                <!-- Welcome Thumb -->
                <div class="welcome-thumb mx-auto">
                    <img src="assets/img/welcome_mockup.png" alt="">
                </div>
            </div>
        </div>
    </div>
    <div class="col-12 col-md-6 col-lg-7 pos-abs-top-right pr-0">
        <div class="hero-wrapper ml-auto d-none d-md-block" style="height: 680px; width: 800px;">
            <!-- Hero Thumb -->
            <div class="svg-clipped hero-thumb bg-img bg-overlay" style="background-image: url(assets/img/blog_thumb.jpg);">
                <img class="d-none" src="assets/img/blog_thumb.jpg" alt="">
            </div>
            <!-- SVG Clip -->
            <svg height="0" width="0">
                <defs>
                    <clipPath id="svgPath">
                        <path d="M0,0c0,0,18.2,428.9,283.2,549.5S655.1,598.4,800,680V0H0z" />
                    </clipPath>
                </defs>
            </svg>
        </div>
    </div>
</section>