<header class="navbar navbar-sticky navbar-expand-lg navbar-dark">
    <div class="container position-relative">
        <a class="navbar-brand" href="index.html">
            <img class="navbar-brand-regular" src="assets/img/image-logo-negativo.svg" width="250px" alt="brand-logo">
            <img class="navbar-brand-sticky" src="assets/img/image-logo.svg" width="250px" alt="sticky brand-logo">
        </a>
        <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>

        <div class="navbar-inner">
            <!--  Mobile Menu Toggler -->
            <button class="navbar-toggler d-lg-none" type="button" data-toggle="navbarToggler" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <nav>
                <ul class="navbar-nav" id="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#home">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#servicos">Serviços</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#cartao">Mercado</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#seguranca">Segurança</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link scroll" href="#contato">Contato</a>
                    </li>
                    <li class="nav-item">
                        <a class="btn btn-danger mt-3 ml-1" href="https://bitbentz-bank-landing.vercel.app/login" target="_blank">Seja um Parceiro</a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>
</header>