<section id="home" class="section welcome-area bg-overlay d-flex align-items-center overflow-hidden">
    <div class="container">
        <div class="row justify-content-center align-items-center">
            <!-- Welcome Intro Start -->
            <div class="col-12 col-md-9 col-lg-7">
                <div class="welcome-intro text-center">
                    <h1 class="text-white">Make cool Landing pages with Appia</h1>
                    <p class="text-white my-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Impedit nihil tenetur minus quidem est deserunt molestias accusamus harum ullam tempore debitis et, expedita, repellat delectus aspernatur neque itaque qui quod.</p>
                    <!-- Video Icon -->
                    <div class="video-icon mt-5">
                        <!-- Play Button -->
                        <a class="play-btn" data-fancybox href="https://www.youtube.com/watch?v=hs1HoLs4SD0">
                            <div class="btn-circle play-animation"></div>
                            <div class="btn-circle play-animation animation-short"></div>
                            <!-- Play Icon -->
                            <div class="play-icon">
                                <i class="fas fa-play"></i>
                            </div>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Shape Bottom -->
    <div class="shape-bottom">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 2200 114.5" style="enable-background:new 0 0 2200 114.5;" xml:space="preserve">
            <style type="text/css">
                .st0 {
                    fill: #FFFFFF;
                }
            </style>
            <path class="st0" d="M0,0L0,0c725.8,128.6,1460.1,129.4,2186.1,2.4L2200,0v114.5H0V0z" />
        </svg>
    </div>
</section>