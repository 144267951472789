import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-theme-two',
  templateUrl: './theme-two.component.html',
  styleUrls: ['./theme-two.component.css']
})
export class ThemeTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
