<section class="section service-area overflow-hidden ptb_100">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-12 col-lg-6 order-2 order-lg-1">
                <!-- Service Text -->
                <div class="service-text pt-4 pt-lg-0">
                    <h2 class="text-capitalize mb-4">Super clean user interface to build your website.</h2>
                    <!-- Check List -->
                    <ul class="check-list style-two">
                        <li class="py-1">
                            <!-- List Box -->
                            <div class="list-box media">
                                <span class="icon align-self-center"><i class="fas fa-check-square"></i></span>
                                <span class="media-body pl-3">Fully layered dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis laboriosam.</span>
                            </div>
                        </li>
                        <li class="py-1">
                            <!-- List Box -->
                            <div class="list-box media">
                                <span class="icon align-self-center"><i class="fas fa-check-square"></i></span>
                                <span class="media-body pl-3">Marketing chart dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis laboriosam.</span>
                            </div>
                        </li>
                        <li class="py-1">
                            <!-- List Box -->
                            <div class="list-box media">
                                <span class="icon align-self-center"><i class="fas fa-check-square"></i></span>
                                <span class="media-body pl-3">Customizable design dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis laboriosam.</span>
                            </div>
                        </li>
                        <li class="py-1">
                            <!-- List Box -->
                            <div class="list-box media">
                                <span class="icon align-self-center"><i class="fas fa-check-square"></i></span>
                                <span class="media-body pl-3">Email management dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores.</span>
                            </div>
                        </li>
                        <li class="py-1">
                            <!-- List Box -->
                            <div class="list-box media">
                                <span class="icon align-self-center"><i class="fas fa-check-square"></i></span>
                                <span class="media-body pl-3">Social marketing dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis.</span>
                            </div>
                        </li>
                    </ul>
                    <a href="#" class="btn btn-bordered mt-4">Read More</a>
                </div>
            </div>
            <div class="col-12 col-lg-4 order-1 order-lg-2">
                <!-- Service Thumb -->
                <div class="service-thumb mx-auto">
                    <!-- Thumb Top -->
                    <div class="thumb-top" data-aos="zoom-in" data-aos-offset="300" data-aos-duration="700">
                        <img src="assets/img/service_thumb_top.png" alt="">
                    </div>
                    <!-- Thumb Certer -->
                    <div class="thumb-center">
                        <img src="assets/img/service_thumb.png" alt="">
                    </div>
                    <!-- Thumb Bottom -->
                    <div class="thumb-bottom" data-aos="fade-left" data-aos-duration="500">
                        <img src="assets/img/service_thumb_bottom.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>