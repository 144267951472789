<footer class="section footer-area overflow-hidden">
    <!-- Footer Top -->
    <div class="footer-top ptb_100">
        <div class="container">
            <div class="row justify-content-center d-none d-sm-flex pt-5 pb-3">
                <div class="col-12 col-sm-10 col-md-7">
                    <!-- Subscribe Form -->
                    <div class="subscribe-form d-flex align-items-center mx-auto">
                        <input type="email" class="form-control" placeholder="Enter your email">
                        <button type="submit" class="btn btn-bordered">Subscribe</button>
                    </div>
                </div>
            </div>
            <div class="row pt-5">
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Logo -->
                        <a class="navbar-brand" href="#">
                            <img class="logo" src="assets/img/logo_white.png" alt="">
                        </a>
                        <p class="text-white mt-2 mb-3">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quis non, fugit totam vel laboriosam vitae.</p>
                        <!-- Social Icons -->
                        <div class="social-icons d-flex">
                            <a class="facebook" href="#">
                                <i class="fab fa-facebook-f"></i>
                                <i class="fab fa-facebook-f"></i>
                            </a>
                            <a class="twitter" href="#">
                                <i class="fab fa-twitter"></i>
                                <i class="fab fa-twitter"></i>
                            </a>
                            <a class="google-plus" href="#">
                                <i class="fab fa-google-plus-g"></i>
                                <i class="fab fa-google-plus-g"></i>
                            </a>
                            <a class="vine" href="#">
                                <i class="fab fa-vine"></i>
                                <i class="fab fa-vine"></i>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title text-white mb-2">Useful Links</h3>
                        <ul>
                            <li class="py-2"><a class="text-white" href="#">Home</a></li>
                            <li class="py-2"><a class="text-white" href="#">About Us</a></li>
                            <li class="py-2"><a class="text-white" href="#">Services</a></li>
                            <li class="py-2"><a class="text-white" href="#">Blog</a></li>
                            <li class="py-2"><a class="text-white" href="#">Contact</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title text-white mb-2">Product Help</h3>
                        <ul>
                            <li class="py-2"><a class="text-white" href="#">FAQ</a></li>
                            <li class="py-2"><a class="text-white" href="#">Privacy Policy</a></li>
                            <li class="py-2"><a class="text-white" href="#">Support</a></li>
                            <li class="py-2"><a class="text-white" href="#">Terms &amp; Conditions</a></li>
                            <li class="py-2"><a class="text-white" href="#">Contact</a></li>
                        </ul>
                    </div>
                </div>
                <div class="col-12 col-sm-6 col-lg-3">
                    <!-- Footer Items -->
                    <div class="footer-items">
                        <!-- Footer Title -->
                        <h3 class="footer-title text-white mb-2">Download</h3>
                        <ul>
                            <li class="py-2"><a class="text-white" href="#">For Windows</a></li>
                            <li class="py-2"><a class="text-white" href="#">For Linux</a></li>
                            <li class="py-2"><a class="text-white" href="#">For IOS</a></li>
                            <li class="py-2"><a class="text-white" href="#">For Android</a></li>
                            <li class="py-2"><a class="text-white" href="#">For Mac</a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <!-- Shape Top -->
        <div class="shape-top">
            <svg fill="#f6f9fe" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1000 100" preserveAspectRatio="none">
                <path class="fill" opacity="0.33" d="M473,67.3c-203.9,88.3-263.1-34-320.3,0C66,119.1,0,59.7,0,59.7V0h1000v59.7 c0,0-62.1,26.1-94.9,29.3c-32.8,3.3-62.8-12.3-75.8-22.1C806,49.6,745.3,8.7,694.9,4.7S492.4,59,473,67.3z"></path>
                <path class="elementor-shape-fill" opacity="0.66" d="M734,67.3c-45.5,0-77.2-23.2-129.1-39.1c-28.6-8.7-150.3-10.1-254,39.1 s-91.7-34.4-149.2,0C115.7,118.3,0,39.8,0,39.8V0h1000v36.5c0,0-28.2-18.5-92.1-18.5C810.2,18.1,775.7,67.3,734,67.3z"></path>
                <path class="elementor-shape-fill" d="M766.1,28.9c-200-57.5-266,65.5-395.1,19.5C242,1.8,242,5.4,184.8,20.6C128,35.8,132.3,44.9,89.9,52.5C28.6,63.7,0,0,0,0 h1000c0,0-9.9,40.9-83.6,48.1S829.6,47,766.1,28.9z"></path>
            </svg>
        </div>
    </div>
    <!-- Footer Bottom -->
    <div class="footer-bottom">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <!-- Copyright Area -->
                    <div class="copyright-area d-flex flex-wrap justify-content-center justify-content-sm-between text-center py-4">
                        <!-- Copyright Left -->
                        <div class="copyright-left text-white">&copy; Copyrights 2021 Appia All rights reserved.</div>
                        <!-- Copyright Right -->
                        <div class="copyright-right text-white">Made with <i class="icofont-heart-alt"></i> By <a class="text-white" href="#">Themeland</a></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>