import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-breadcrumb-nine',
  templateUrl: './breadcrumb-nine.component.html',
  styleUrls: ['./breadcrumb-nine.component.css']
})
export class BreadcrumbNineComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
