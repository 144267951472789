<div>
    <div class="inner-wrapper d-flex flex-column align-items-center justify-content-between py-4 p-sm-4">
        <a href="/">
            <img src="assets/img/logo.png" alt="">
        </a>
        <!-- ***** Maintenance Area Start ***** -->
        <div class="maintenance-area mb-5 mb-lg-0">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-10 col-sm-8 col-lg-5">
                        <!-- Maintenance Thumb -->
                        <div class="maintenance-thumb mx-auto py-5">
                            <img src="assets/img/maintenance_thumb.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-12 col-md-10 col-lg-7 mx-auto">
                        <!-- Maintenance Content -->
                        <div class="maintenance-content text-center">
                            <h2 class="color-secondary pt-3 pt-md-5 fw-4">Server is under maintenance</h2>
                            <p class="my-4">Sorry for the innovance, promotions adipisicing elit. Architecto beatae, asperiores tempore repudiandae saepe aspernatur unde voluptate sapiente quia ex.</p>
                            <!-- Subscribe Form -->
                            <div class="subscribe-form d-flex align-items-center mx-auto mt-4 mb-5">
                                <input type="email" class="form-control" placeholder="Email">
                                <button type="submit" class="btn btn-bordered">Notify me</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ***** Maintenance Area End ***** -->
        <div class="footer-bottom">
            <!-- Copyright Area -->
            <div class="copyright-area border-0 pt-4 pt-md-0">
                <p>Made with <i class="icofont-heart-alt"></i> By <a href="#">Themeland</a></p>
            </div>
        </div>
    </div>
</div>