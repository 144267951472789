<section id="contato" class="contact-area bg-gray ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-6">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Entre em contato</h2>
                    <p class="d-none d-sm-block mt-4">Estamos pronto para receber suas dúvidas, sugestões ou reclamações, estamos aqui para melhor te atender!</p>
                    <p class="d-block d-sm-none mt-4">Estamos pronto para receber suas dúvidas, sugestões ou reclamações, estamos aqui para melhor te atender!</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <!-- Contact Box -->
                <div class="contact-box text-center">
                    <!-- Contact Form -->
                    <form id="contact-form">
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <input type="text" class="form-control" name="name" placeholder="Nome completo" required="required">
                                </div>
                                <div class="form-group">
                                    <input type="email" class="form-control" name="email" placeholder="E-mail" required="required">
                                </div>
                                <div class="form-group">
                                    <input type="text" class="form-control" name="subject" placeholder="Assunto" required="required">
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="form-group">
                                    <textarea class="form-control" name="message" placeholder="Mensagem" required="required"></textarea>
                                </div>
                            </div>
                            <div class="col-12">
                                <button class="btn btn-bordered mt-3 mt-sm-4" type="submit">Enviar</button>
                            </div>
                        </div>
                    </form>
                    <p class="form-message"></p>
                </div>
            </div>
        </div>
    </div>
</section>