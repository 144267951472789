<div>
    <div class="inner-wrapper d-flex flex-column p-4">
        <a class="align-self-center" href="/">
            <img src="assets/img/logo.png" alt="">
        </a>
        <!-- ***** Login Area Start ***** -->
        <div class="login-area py-5">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-sm-10 col-md-8 col-lg-6 inner-content text-center">
                        <h2 class="text-primary fw-4">Sign In</h2>
                        <p class="pt-3 pb-5">Don't have an account? <a class="color-primary" href="/signup">Create an account</a></p>
                        <form class="inner-form text-left">
                            <div class="form-group">
                                <label for="exampleInputEmail1">Email address</label>
                                <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email">
                                <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small>
                            </div>
                            <div class="form-group">
                                <div class="form-label d-flex justify-content-between">
                                    <label for="exampleInputPassword1">Password</label>
                                    <a class="color-primary" href="#">Forgot Password</a>
                                </div>
                                <input type="password" class="form-control" id="exampleInputPassword1" placeholder="Password must be 8 characters">
                            </div>
                            <div class="form-group form-check">
                                <input type="checkbox" class="form-check-input" id="exampleCheck1">
                                <label class="form-check-label" for="exampleCheck1">Remember me</label>
                            </div>
                            <button type="submit" class="btn btn-primary btn-lg btn-block">Login</button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        <!-- ***** Login Area End ***** -->
        <div class="footer-bottom align-self-center">
            <!-- Copyright Area -->
            <div class="copyright-area border-0 pt-4 pt-md-0">
                <p>Made with <i class="icofont-heart-alt"></i> By <a href="#">Themeland</a></p>
            </div>
        </div>
    </div>
</div>