<section class="section counter-area ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-5 col-sm-3 text-center">
                <!-- Single Counter -->
                <div class="single-counter d-inline-block m-2 m-sm-0">
                    <!-- Counter -->
                    <div class="counter mb-3">
                        <span class="p-2">1M</span>
                    </div>
                    <h5>Users</h5>
                </div>
            </div>
            <div class="col-5 col-sm-3 text-center">
                <!-- Single Counter -->
                <div class="single-counter d-inline-block m-2 m-sm-0">
                    <!-- Counter -->
                    <div class="counter mb-3">
                        <span class="p-2">23K</span>
                    </div>
                    <h5>Download</h5>
                </div>
            </div>
            <div class="col-5 col-sm-3 text-center">
                <!-- Single Counter -->
                <div class="single-counter d-inline-block m-2 m-sm-0">
                    <!-- Counter -->
                    <div class="counter mb-3">
                        <span class="p-2">9M</span>
                    </div>
                    <h5>Customer</h5>
                </div>
            </div>
            <div class="col-5 col-sm-3 text-center">
                <!-- Single Counter -->
                <div class="single-counter d-inline-block m-2 m-sm-0">
                    <!-- Counter -->
                    <div class="counter mb-3">
                        <span class="p-2">12K</span>
                    </div>
                    <h5>Developer</h5>
                </div>
            </div>
        </div>
    </div>
</section>