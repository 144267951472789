<section class="section faq-area ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Have any questions? We are here to assist you</h2>
                    <p class="d-none d-sm-block mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati dignissimos quae quo ad iste ipsum officiis deleniti asperiores sit.</p>
                    <p class="d-block d-sm-none mt-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Laborum obcaecati.</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
                <!-- FAQ Content -->
                <div class="faq-content">
                    <!-- Appia Accordion -->
                    <div class="accordion" id="appia-accordion">
                        <!-- Single Accordion Item -->
                        <div class="card border-0 my-2">
                            <!-- Card Header -->
                            <div class="card-header bg-white">
                                <h2 class="mb-0">
                                    <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseOne">
                                        Can I pay for my order by credit card?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseOne" class="collapse" data-parent="#appia-accordion">
                                <!-- Card Body -->
                                <div class="card-body px-5">
                                    Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose injected humour and the like.
                                </div>
                            </div>
                        </div>
                        <!-- Single Accordion Item -->
                        <div class="card border-0 my-2">
                            <!-- Card Header -->
                            <div class="card-header bg-white">
                                <h2 class="mb-0">
                                    <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseTwo">
                                        How secure is shopping in the Online shop?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseTwo" class="collapse" data-parent="#appia-accordion">
                                <!-- Card Body -->
                                <div class="card-body px-5">
                                    Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose injected humour and the like.
                                </div>
                            </div>
                        </div>
                        <!-- Single Accordion Item -->
                        <div class="card border-0 my-2">
                            <!-- Card Header -->
                            <div class="card-header bg-white">
                                <h2 class="mb-0">
                                    <button class="btn" type="button" data-toggle="collapse" data-target="#collapseThree">
                                        How to install Appia?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseThree" class="collapse show" data-parent="#appia-accordion">
                                <!-- Card Body -->
                                <div class="card-body px-5">
                                    There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum, you need to be sure there isn't anything embarrassing hidden in the middle of text.
                                </div>
                            </div>
                        </div>
                        <!-- Single Accordion Item -->
                        <div class="card border-0 my-2">
                            <!-- Card Header -->
                            <div class="card-header bg-white">
                                <h2 class="mb-0">
                                    <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseFour">
                                        Is shipping included in the prices?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseFour" class="collapse" data-parent="#appia-accordion">
                                <!-- Card Body -->
                                <div class="card-body px-5">
                                    Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose injected humour and the like.
                                </div>
                            </div>
                        </div>
                        <!-- Single Accordion Item -->
                        <div class="card border-0 my-2">
                            <!-- Card Header -->
                            <div class="card-header bg-white">
                                <h2 class="mb-0">
                                    <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseFive">
                                        Contact form isn't working?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseFive" class="collapse" data-parent="#appia-accordion">
                                <!-- Card Body -->
                                <div class="card-body px-5">
                                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                                </div>
                            </div>
                        </div>
                        <!-- Single Accordion Item -->
                        <div class="card border-0 my-2">
                            <!-- Card Header -->
                            <div class="card-header bg-white">
                                <h2 class="mb-0">
                                    <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseSix">
                                        Is it possible to refund or replace any product?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseSix" class="collapse" data-parent="#appia-accordion">
                                <!-- Card Body -->
                                <div class="card-body px-5">
                                    Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model text, and a search for 'lorem ipsum' will uncover many web sites still in their infancy. Various versions have evolved over the years, sometimes by accident, sometimes on purpose injected humour and the like.
                                </div>
                            </div>
                        </div>
                        <!-- Single Accordion Item -->
                        <div class="card border-0 my-2">
                            <!-- Card Header -->
                            <div class="card-header bg-white">
                                <h2 class="mb-0">
                                    <button class="btn collapsed" type="button" data-toggle="collapse" data-target="#collapseSeven">
                                        Why was my order cancelled?
                                    </button>
                                </h2>
                            </div>
                            <div id="collapseSeven" class="collapse" data-parent="#appia-accordion">
                                <!-- Card Body -->
                                <div class="card-body px-5">
                                    It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="contact-button text-center">
                        <span class="d-block pt-3 pb-4">Still having trouble? Don't hesitate to contact us.</span>
                        <a href="#" class="btn btn-bordered">Contact Us</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>