<section class="section download-area bg-img bg-overlay ptb_100">
    <div class="container">
        <div class="row">
            <div class="col-12 col-md-8 col-lg-7">
                <!-- Download Text -->
                <div class="download-text">
                    <h2 class="text-white">Download Appia</h2>
                    <p class="text-white my-3 d-none d-sm-block">Appia is available for all devices, consectetur adipisicing elit. Itaque at harum quam explicabo. Aliquam optio, delectus, dolorem quod neque eos totam. Delectus quae animi tenetur voluptates doloribus commodi dicta modi aliquid deserunt, quis maiores nesciunt autem, aperiam natus.</p>
                    <p class="text-white my-3 d-block d-sm-none">Appia is available for all devices, consectetur adipisicing elit. Vel neque, cumque. Temporibus eligendi veniam, necessitatibus aut id labore nisi quisquam.</p>
                    <!-- Store Buttons -->
                    <div class="button-group store-buttons d-flex">
                        <a href="#">
                            <img src="assets/img/google_play.svg" alt="">
                        </a>
                        <a href="#">
                            <img src="assets/img/app_store.svg" alt="">
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- Download Thumb -->
    <div class="download-thumb pos-abs-top-right d-none d-md-block" data-aos="fade-up" data-aos-offset="300" data-aos-duration="1000">
        <img src="assets/img/download_thumb.png" alt="">
    </div>
</section>