<div>
    <div class="inner-wrapper d-flex flex-column align-items-center justify-content-between p-4">
        <a href="/">
            <img src="assets/img/logo.png" alt="">
        </a>
        <!-- ***** Forgot Area Start ***** -->
        <div class="forgot-area">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 inner-content text-center">
                        <h2 class="text-primary fw-4">Forgot your Password?</h2>
                        <p class="pt-3 pb-5">Enter your email. We'll send you a password reset link.</p>
                        <div class="inner-form">
                            <input type="email" class="form-control form-control-lg" placeholder="Enter your Email">
                            <button type="button" class="btn btn-primary btn-lg btn-block mt-3">Reset Password</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- ***** Forgot Area End ***** -->
        <div class="footer-bottom">
            <!-- Copyright Area -->
            <div class="copyright-area border-0 pt-4 pt-md-0">
                <p>Made with <i class="icofont-heart-alt"></i> By <a href="#">Themeland</a></p>
            </div>
        </div>
    </div>
</div>