<section id="seguranca" class="section counter-area ptb_100">
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-12 col-md-10 col-lg-8">
                <!-- Section Heading -->
                <div class="section-heading text-center">
                    <h2 class="text-capitalize">Segurança em Blockchain para suas transações</h2>
                    <p class="d-none d-sm-block mt-4">Blockchain é a tecnologia que garante a segurança das transações, pois permite rastrear o envio e o recebimento de informações. Conheça nossos serviços e soluções em Blockchain Privada ou Pública.</p> 
                    
                    <p class="d-block d-sm-none mt-4">Blockchain é a tecnologia que garante a segurança das transações, pois permite rastrear o envio e o recebimento de informações. Conheça nossos serviços e soluções em Blockchain Privada ou Pública.</p>
                </div>
            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-5 col-sm-3 text-center">
                <!-- Single Counter -->
                <div class="single-counter d-inline-block m-2 m-sm-0">
                    <!-- Counter -->
                    <div class="counter mb-3">
                        <span class="p-2">100%</span>
                    </div>
                    <h5>Imutabilidade</h5>
                </div>
            </div>
            <div class="col-5 col-sm-3 text-center">
                <!-- Single Counter -->
                <div class="single-counter d-inline-block m-2 m-sm-0">
                    <!-- Counter -->
                    <div class="counter mb-3">
                        <span class="p-2">100%</span>
                    </div>
                    <h5>Segura</h5>
                </div>
            </div>
            <div class="col-5 col-sm-3 text-center">
                <!-- Single Counter -->
                <div class="single-counter d-inline-block m-2 m-sm-0">
                    <!-- Counter -->
                    <div class="counter mb-3">
                        <span class="p-2">100%</span>
                    </div>
                    <h5>Transparência</h5>
                </div>
            </div>
        
        </div>
    </div>
</section>