<div>
    <div class="inner-wrapper d-flex flex-column align-items-center justify-content-between py-2 p-sm-4">
        <a href="/">
            <img src="assets/img/logo.png" alt="">
        </a>
        <!-- ***** Error Area Start ***** -->
        <div class="error-area">
            <div class="container">
                <div class="row">
                    <div class="col-12 col-sm-8 col-lg-6 mx-auto text-center py-4 py-lg-0">
                        <img src="assets/img/error_thumb_1.png" alt="">
                    </div>
                    <div class="col-12 col-md-10 col-lg-6 mx-auto text-center text-lg-left">
                        <h1>404</h1>
                        <h2 class="fw-4">Sorry, Something went wrong!</h2>
                        <p class="mt-3 mb-4">The page you are looking for might have been removed had its name changed or is temporarily unavailable.</p>
                        <a href="/" class="btn btn-bordered"><span>Go to Homepage</span></a>
                    </div>
                </div>
            </div>
        </div>
        <!-- ***** Error Two Area End ***** -->
        <div class="footer-bottom">
            <!-- Copyright Area -->
            <div class="copyright-area border-0 p-3">
                <p>Made with <i class="icofont-heart-alt"></i> By <a href="#">Themeland</a></p>
            </div>
        </div>
    </div>
</div>