<section class="section discover-area ptb_100">
    <div class="container">
        <div class="row justify-content-between align-items-center">
            <div class="col-12 col-lg-6">
                <!-- Discover Thumb -->
                <div class="service-thumb style-two discover-thumb mx-auto">
                    <img src="assets/img/service_thumb_2.png" alt="">
                </div>
            </div>
            <div class="col-12 col-lg-6">
                <!-- Discover Text -->
                <div class="discover-text pt-4 pt-lg-0">
                    <h2 class="text-capitalize">Super clean user interface for easier use.</h2>
                    <p class="d-none d-sm-block pt-3 pb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Similique dolor ut iusto vitae autem neque eum ipsam, impedit asperiores vel cumque laborum dicta repellendus inventore voluptatibus et explicabo nobis unde.</p>
                    <!-- Appia Tab -->
                    <ul class="appia-tab nav nav-tabs pt-4 pt-sm-0 mb-3" id="nav-tab">
                        <li class="nav-item">
                            <a class="nav-link active" id="nav-home-tab" data-toggle="pill" href="#nav-home">
                                <span><i class="fas fa-bell"></i></span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="nav-profile-tab" data-toggle="pill" href="#nav-profile">
                                <span><i class="fas fa-envelope-open"></i></span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" id="nav-contact-tab" data-toggle="pill" href="#nav-contact">
                                <span><i class="fas fa-video"></i></span>
                            </a>
                        </li>
                    </ul>
                    <!-- Tab Content -->
                    <div class="tab-content" id="nav-tabContent">
                        <div class="tab-pane fade show active" id="nav-home">
                            <!-- Check List -->
                            <ul class="check-list">
                                <li class="py-1">
                                    <!-- List Box -->
                                    <div class="list-box media">
                                        <span class="icon align-self-center"><i class="fas fa-check"></i></span>
                                        <span class="media-body pl-2">Combined with a handful of model sentence structures looks reasonable.</span>
                                    </div>
                                </li>
                                <li class="py-1">
                                    <!-- List Box -->
                                    <div class="list-box media">
                                        <span class="icon align-self-center"><i class="fas fa-check"></i></span>
                                        <span class="media-body pl-2">Contrary to popular belief, Lorem Ipsum is not simply random text.</span>
                                    </div>
                                </li>
                                <li class="py-1">
                                    <!-- List Box -->
                                    <div class="list-box media">
                                        <span class="icon align-self-center"><i class="fas fa-check"></i></span>
                                        <span class="media-body pl-2">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium.</span>
                                    </div>
                                </li>
                                <li class="py-1">
                                    <!-- List Box -->
                                    <div class="list-box media">
                                        <span class="icon align-self-center"><i class="fas fa-check"></i></span>
                                        <span class="media-body pl-2">All the Lorem Ipsum generators on the Internet tend to repeat necessary.</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="tab-pane fade" id="nav-profile">
                            <!-- Check List -->
                            <ul class="check-list">
                                <li class="py-1">
                                    <!-- List Box -->
                                    <div class="list-box media">
                                        <span class="icon align-self-center"><i class="fas fa-check"></i></span>
                                        <span class="media-body pl-2">Fully layered dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis laboriosam.</span>
                                    </div>
                                </li>
                                <li class="py-1">
                                    <!-- List Box -->
                                    <div class="list-box media">
                                        <span class="icon align-self-center"><i class="fas fa-check"></i></span>
                                        <span class="media-body pl-2">Marketing chart dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis laboriosam.</span>
                                    </div>
                                </li>
                                <li class="py-1">
                                    <!-- List Box -->
                                    <div class="list-box media">
                                        <span class="icon align-self-center"><i class="fas fa-check"></i></span>
                                        <span class="media-body pl-2">Customizable design dolor sit amet, consectetur adipisicing elit. Facere, nobis, id expedita dolores officiis laboriosam.</span>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        <div class="tab-pane fade" id="nav-contact">
                            There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage.
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>