<section class="section discover-area ptb_100">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-12 col-lg-5">
                <!-- Discover Thumb -->
                <div class="service-thumb discover-thumb mx-auto">
                    <!-- Thumb Top -->
                    <div class="thumb-top">
                        <img src="assets/img/discover_thumb_top.png" alt="">
                    </div>
                    <!-- Thumb Certer -->
                    <div class="thumb-center">
                        <img src="assets/img/discover_thumb.png" alt="">
                    </div>
                    <!-- Thumb Bottom -->
                    <div class="thumb-bottom">
                        <img src="assets/img/discover_thumb_bottom.png" alt="">
                    </div>
                </div>
            </div>
            <div class="col-12 col-lg-7">
                <!-- Discover Text -->
                <div class="discover-text pt-4 pt-lg-0">
                    <h2 class="text-capitalize">Discover Unstoppable Fun with Even More Possibilities</h2>
                    <p class="pt-2 pb-4">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Similique dolor ut iusto vitae autem neque eum ipsam, impedit asperiores vel cumque laborum dicta repellendus inventore voluptatibus et explicabo nobis unde.</p>
                    <div class="row">
                        <div class="col-12 col-md-6">
                            <!-- Icon Box -->
                            <div class="icon-box media icon-1 px-1 py-3 py-md-4">
                                <!-- Icon -->
                                <div class="icon rounded-circle border mr-3">
                                    <span><i class="fab fa-git-alt"></i></span>
                                </div>
                                <!-- Icon Text -->
                                <div class="icon-text media-body align-self-center align-self-md-start">
                                    <h3 class="mb-2">Clean Code</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <!-- Icon Box -->
                            <div class="icon-box media icon-2 px-1 py-3 py-md-4">
                                <!-- Icon -->
                                <div class="icon rounded-circle border mr-3">
                                    <span><i class="fab fa-steam"></i></span>
                                </div>
                                <!-- Icon Text -->
                                <div class="icon-text media-body align-self-center align-self-md-start">
                                    <h3 class="mb-2">Responsive Design</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <!-- Icon Box -->
                            <div class="icon-box media icon-3 px-1 py-3 py-md-4">
                                <!-- Icon -->
                                <div class="icon rounded-circle border mr-3">
                                    <span><i class="fab fa-snapchat"></i></span>
                                </div>
                                <!-- Icon Text -->
                                <div class="icon-text media-body align-self-center align-self-md-start">
                                    <h3 class="mb-2">Live Chat</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                                </div>
                            </div>
                        </div>
                        <div class="col-12 col-md-6">
                            <!-- Icon Box -->
                            <div class="icon-box media icon-4 px-1 py-3 py-md-4">
                                <!-- Icon -->
                                <div class="icon rounded-circle border mr-3">
                                    <span><i class="fab fa-github"></i></span>
                                </div>
                                <!-- Icon Text -->
                                <div class="icon-text media-body align-self-center align-self-md-start">
                                    <h3 class="mb-2">Easy to Customize</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>