<section class="start-free-area style-two">
    <div class="container">
        <div class="col-12">
            <!-- Start Free Content -->
            <div class="start-free-content text-center shadow-lg" data-aos="fade-up" data-aos-duration="800">
                <!-- Start Free Content -->
                <div class="start-free-text">
                    <strong><h3>Empréstimos e Financiamentos</h3></strong>
                    <h2 class="mt-2 mb-3">Trabalhamos com as melhores opções financeiras do mercado.</h2>
                    <p class="start-free-details d-none d-lg-block">  <a class="btn btn-danger mt-3 ml-1" href="https://bitbentz-bank-landing.vercel.app/loans" target="_blank">Fale com um acessor</a></p>
                    <p class="start-free-details d-block d-lg-none"> <a class="btn btn-danger mt-3 ml-1" href="https://bitbentz-bank-landing.vercel.app/loans" target="_blank">Fale com um acessor</a></p>
                    <!-- Store Buttons -->
                    
                </div>
            </div>
        </div>
    </div>
</section>