<section id="cartao" class="section service-area bg-gray overflow-hidden ptb_100">
    <div class="container">
        <div class="row justify-content-between">
            <div class="col-12 col-lg-6 order-2 order-lg-1">
                <!-- Service Text -->
                <div class="service-text pt-4 pt-lg-0">
                    <h2 class="text-capitalize mb-4">Mercado</h2>
                    <!-- Service List -->
<div class="row">
    <div class="col-md-6">
        <ul class="service-list">
            <!-- Single Service -->
            <li class="single-service media py-2">
                <div class="service-icon pr-4">
                    <span>  <img src="assets/img/modelo-mercado/icon-1.svg" width="30px" alt=""></span>
                </div>
                <div class="service-text media-body mt-3">
                    <p> Emissão de Nota Fiscal</p>
                </div>
            </li>

            <li class="single-service media py-2">
                <div class="service-icon pr-4">
                    <span>  <img src="assets/img/modelo-mercado/icon-2.png" width="30px" alt=""></span>
                </div>
                <div class="service-text media-body mt-3">
                    <p> Gestão Financeira</p>
                </div>
            </li>

            <li class="single-service media py-2">
                <div class="service-icon pr-4">
                    <span>  <img src="assets/img/modelo-mercado/icon-4.png" width="30px" alt=""></span>
                </div>
                <div class="service-text media-body mt-3">
                    <p> Vendas e Marketplace</p>
                </div>
            </li>

            <li class="single-service media py-2">
                <div class="service-icon pr-4">
                    <span>  <img src="assets/img/modelo-mercado/icon-5.png" width="30px" alt=""></span>
                </div>
                <div class="service-text media-body mt-3">
                    <p> Integração Contábil</p>
                </div>
            </li>


            <li class="single-service media py-2">
                <div class="service-icon pr-4">
                    <span>  <img src="assets/img/modelo-mercado/icon-6.png" width="30px" alt=""></span>
                </div>
                <div class="service-text media-body mt-3">
                    <p> Cashback tokenizado</p>
                </div>
            </li>

            <li class="single-service media py-2">
                <div class="service-icon pr-4">
                    <span>  <img src="assets/img/modelo-mercado/icon-7.svg" width="30px" alt=""></span>
                </div>
                <div class="service-text media-body mt-3">
                    <p> Autenticação de identidades</p>
                </div>
            </li>
           
         
        </ul>
    </div>

    <div class="col-md-6">
        <ul class="service-list">
            <!-- Single Service -->
            <li class="single-service media py-2">
                <div class="service-icon pr-4">
                    <span>  <img src="assets/img/modelo-mercado/icon-8.png" width="30px" alt=""></span>
                </div>
                <div class="service-text media-body mt-3">
                    <p> Registro de transações</p>
                </div>
            </li>

            <li class="single-service media py-2">
                <div class="service-icon pr-4">
                    <span>  <img src="assets/img/modelo-mercado/icon-9.svg" width="30px" alt=""></span>
                </div>
                <div class="service-text media-body mt-3">
                    <p> Fechamento Fiscal e Contábil</p>
                </div>
            </li>

            <li class="single-service media py-2">
                <div class="service-icon pr-4">
                    <span>  <img src="assets/img/modelo-mercado/icon-10.svg" width="30px" alt=""></span>
                </div>
                <div class="service-text media-body mt-3">
                    <p> Smart Contracts</p>
                </div>
            </li>

            <li class="single-service media py-2">
                <div class="service-icon pr-4">
                    <span>  <img src="assets/img/modelo-mercado/icon-11.svg" width="30px" alt=""></span>
                </div>
                <div class="service-text media-body mt-3">
                    <p> 100% Dados seguros</p>
                </div>
            </li>


            <li class="single-service media py-2">
                <div class="service-icon pr-4">
                    <span>  <img src="assets/img/modelo-mercado/icon-15.svg" width="30px" alt=""></span>
                </div>
                <div class="service-text media-body mt-3">
                    <p> APIs Independentes</p>
                </div>
            </li>

            <li class="single-service media py-2">
                <div class="service-icon pr-4">
                    <span>  <img src="assets/img/modelo-mercado/icon-7.svg" width="30px" alt=""></span>
                </div>
                <div class="service-text media-body mt-3">
                    <p> Autenticação de identidades</p>
                </div>
            </li>
           
         
        </ul>
    </div>
</div>

                    
                  
                </div>
            </div>
            <div class="col-12 col-lg-4 order-1 order-lg-2">
                <!-- Service Thumb -->
                <div class="service-thumb mx-auto">
                    <!-- Thumb Top -->
                    <div class="thumb-top" data-aos="zoom-in" data-aos-offset="300" data-aos-duration="700">
                        <img src="assets/img/service_thumb_top.png" alt="">
                    </div>
                    <!-- Thumb Certer -->
                    <div class="thumb-center">
                        <img src="assets/img/service_thumb.png" alt="">
                    </div>
                    <!-- Thumb Bottom -->
                    <div class="thumb-bottom" data-aos="fade-left" data-aos-duration="500">
                        <img src="assets/img/service_thumb_bottom.png" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>